import M from 'backbone.marionette'
import Dropzone from 'dropzone'
import css from 'dropzone/dist/dropzone.css'

Dropzone.prototype.defaultOptions.dictDefaultMessage = "<big><strong>Téléverser un document</strong></big><br />Cliquer ici ou glisser dans cette zone le(s) document(s) à téléverser.";//"Drop files here to upload";
Dropzone.prototype.defaultOptions.dictFallbackMessage = "Votre navigateur ne supporte pas le dépôt d'un fichier dans cette zone"; //"Your browser does not support drag'n'drop file uploads.";
Dropzone.prototype.defaultOptions.dictFallbackText = "Utilisez le formulaire ci dessous pour déoser votre fichier";//"Please use the fallback form below to upload your files like in the olden days.";
Dropzone.prototype.defaultOptions.dictFileTooBig = "Ce fichier et trop volumineux ({{filesize}} Mb). Taille maximale : {{maxFilesize}}Mb.";//"File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
Dropzone.prototype.defaultOptions.dictInvalidFileType = "Impossible de téléverser ce type de fichier";//"You can't upload files of this type.";
Dropzone.prototype.defaultOptions.dictResponseError = "Le serveur a répondu avec le code {{statusCode}}.";//"Server responded with {{statusCode}} code.";
Dropzone.prototype.defaultOptions.dictCancelUpload = "Annuler le téléversement";//"Cancel upload";
Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Êtez-vous sûr de vouloir annuler ce téléversement ?";//"Are you sure you want to cancel this upload?";
Dropzone.prototype.defaultOptions.dictRemoveFile = "Retirer ce fichier";//"Remove file";
Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "Vous ne pouvez téléverser plus de fichier";//"You can not upload any more files.";

export const Uploader = M.View.extend({
    template: _.template(''),
    id: 'upload',
    className: 'dropzone',
    dropZoneInstance: null,
    initialize: function(){
        if($('style[data-dropzone]').length==0){
            $('head').append('<style data-dropzone>' + css + '</style>');
        }
    },
    onAttach: function(){
        let url = 'api/data/upload';
        if(this.getOption('related_type'))url += '/' + this.getOption('related_type');
        if(this.getOption('related_id'))url += '/' + this.getOption('related_id');
        this.dropZoneInstance = new Dropzone(this.el, {
            url: url,
            allowMultiple: false,
            maxFilesize: 4,
            acceptedFiles: '.doc,.docx,.pdf,.xls,.xlsx,.gif,.jpg,.png,.jpeg,.zip,.bmp'
        })
        this.dropZoneInstance.on("success", _.bind(this._onUpload, this));
    },
    onDetach: function(){
        if(this.dropZoneInstance){
            this.dropZoneInstance.off("success");
            this.dropZoneInstance.destroy();
            this.dropZoneInstance = null;
        }
    },
    _onUpload: function(file, document){
        this.triggerMethod('uploaded', document);
    }
});