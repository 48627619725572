import Dropzone from 'dropzone'
import css from 'dropzone/dist/dropzone.css'
import { showModal } from '../../utils/bootstrap/modal'

export const showUploadPhotoDialog = (options) => {
    const v = new PhotoUpload(options);
    showModal('Téléverser une image', v);
}

const PhotoUpload = M.View.extend({
    template: _.template(''),
    id: 'upload',
    className: 'dropzone',
    dropZoneInstance: null,
    initialize: function () {
        if ($('style[data-dropzone]').length == 0) {
            $('head').append('<style data-dropzone>' + css + '</style>');
        }
    },
    onAttach: function () {
        this.dropZoneInstance = new Dropzone(this.el, _.extend({
            url: 'api/data/uploadphoto/',
            allowMultiple: false,
            acceptedFiles: '.jpg,.jpeg,.png'
        }, this.getOption('dropzoneOptions') ?? {}))
        this.dropZoneInstance.on("success", _.bind(this._onUpload, this));
    },
    onDetach: function () {
        if (this.dropZoneInstance) {
            this.dropZoneInstance.off("success");
            this.dropZoneInstance.destroy();
            this.dropZoneInstance = null;
        }
    },
    _onUpload: function (file, uploadedImageUrl) {
        this.triggerMethod('uploaded', uploadedImageUrl);
    }
});